import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Modal,
  Backdrop,
  Button,
  Typography,
} from '@mui/material';
import Loading from '../../../../components/common/Loading';
import { useDebouncedCallback } from 'use-debounce';
import ORBApi from '../../../../Services/ORBApi';
import ContractualPartsForm from '../../../ContractualParts/components/ContractualPartsForm';
import './style.css';

// props: value, onChange, label, error
const SuggestContractualParts = (props) => {
  const emptyContractualPart = { id: '', name: '' };
  const [value, setValue] = useState({ ...emptyContractualPart });
  const [contractualParts, setContractualParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState("")
  const [filters, setFilters] = useState({ name: '' });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const debounced = useDebouncedCallback((value) => {
    setFilters({ ...filters, name: value.toUpperCase() });
  }, 500);

  const loadData = (page, filters) => {
    setLoading(true);
    ORBApi.loadSuggestContractualParts(page, filters)
      .then((data) => {
        setContractualParts(data.parts);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickContractualPart = (contractualPart) => {
    //clean filtes
    setFilters({ ...filters, name: '' });

    // set new selected Contractual parts
    let newValue = {
      id: contractualPart.id,
      name: getFormattedName(contractualPart),
    };
    setSelectedPart(getFormattedName(contractualPart))
    setValue(newValue);
    props.onChange(newValue);
  };

  const getFormattedName = (contractualPart) => {
    if (contractualPart.entity_type === 'PF') {
      return `${contractualPart.full_name} (${contractualPart.entity_type})`;
    } else {
      return `${contractualPart.company} (${contractualPart.entity_type})`;
    }
  };

  const handleChange = (value) => {
    if(value === "") props.onChange(value);
    debounced(value);
    setLoading(true);
    setValue({ ...emptyContractualPart, name: value });
    if(!value){
      setSelectedPart("")
    }
  };

  useEffect(() => {
    if (filters.name) {
      loadData(1, filters);
    }
    if (filters.name === '') {
      setContractualParts(null);
    }
  }, [filters]);
  
  useEffect(() => {
    setValue({...emptyContractualPart, name:props.value})
    setSelectedPart(props.value)
  },[props.value])

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        fullWidth
        error={props.error && !selectedPart}
        value={value.name}
        label={props.label}
        variant="outlined"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
      {filters.name ? (
        <Grid className="contractual-parts-container">
          {contractualParts?.length > 0 ? (
            <>
              <ul className="contractual-parts-list">
                {contractualParts.map((contractualPart) => (
                  <li
                    key={contractualPart.id}
                    onClick={() => {
                      onClickContractualPart(contractualPart);
                    }}
                  >
                    {getFormattedName(contractualPart)}
                  </li>
                ))}
              </ul>
              <Typography align="center">
                <Button size="small" onClick={() => setVisible(true)}>
                  Criar parte contratual
                </Button>
              </Typography>
            </>
          ) : loading ? (
            <ul className="contractual-parts-list">
              <li>
                <Loading className="loading-box" size="20px" />
              </li>
            </ul>
          ) : (
            <Typography align="center">
              <Button size="small" onClick={() => setVisible(true)}>
                Criar parte contratual
              </Button>
            </Typography>
          )}
        </Grid>
      ) : null}
      <Modal
        size="lg"
        open={visible}
        onClose={() => {
          setVisible(!visible);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeogut: 500 }}
      >
        <div style={{ width: '80%' }} className="modal-container">
          <ContractualPartsForm setIsVisible={setVisible} />
        </div>
      </Modal>
    </div>
  );
};
export default SuggestContractualParts;
