import React from "react";
import ExcelIcon from '../../../icons/excel.svg';
import ContractTableDownloadIcon from "./ContractTableDownloadIcon";

const ContractTableDownloadIconExcel = ({ contract_id, contract_name }) => {
    return (
        <ContractTableDownloadIcon
            contract_id={contract_id}
            contract_name={contract_name}
            fileType="xlsx"
            icon={<img style={{maxWidth:'20px',marginBottom:'5px'}} src={ExcelIcon} alt="Ícone de Excel"/>}
            mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
    );
};

export default ContractTableDownloadIconExcel;