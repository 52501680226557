import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../components/common/Loading';
import { Grid, TextField, Button, Paper, Tabs, Tab, Tooltip, Slide} from '@mui/material';
import AlertIcon from '@mui/icons-material/ErrorOutline';
import ORBApi from '../../../Services/ORBApi';
import TemplatesDropdown from './TemplatesDropdown';
import moment from 'moment';
import SuggestContractualParts from './SuggestContractualParts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ContractsForm = (props) => {
  const [contract, setContract] = useState({
    template: '',
    name: '',
    client_name: '',
    expires_at: '',
    variables: {},
    sign_strings:[]
  });
  const [variables, setVariables] = useState(null);
  const [signStrings, setSignStrings] = useState(null)
  const [templateId, setTemplateId] = useState('');
  const [signContractualParts, setSignContractualParts] = useState({});
  const [signContractualPartsId, setSignContractualPartsId] = useState({})
  const [variablesContractualParts, setVariablesContractualParts] = useState({})
  const [dateValue, setDateValue] = useState(null);
  const [title, setTitle] = useState('');
  const [contractError, setContractError] = useState(false)
  const [contractVariablesError, setContractVariablesError] = useState(false)
  const [contractAnchorsError, setContractAnchorsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState(0);
  const [showContent, setShowContent] = useState(true);


  const loadVariables = (templateId) => {
    setLoading(1);
    if (templateId) {
      ORBApi.loadTemplateDetails(templateId)
        .then((data) => {
          setVariables(data.variables);
          setSignStrings(data.sign_strings)
          
          setVariablesContractualParts(
            data.variables.reduce((acc, key) => {
              acc[key] = "";
              return acc;
            }, {})
          );
          setSignContractualParts(
            data.sign_strings.reduce((acc, key) => {
              acc[key] = "";
              return acc;
            }, {})
          );
        })
        .finally(() => {
          setLoading(0);
        });
    }
  };

const validateContractData = () => {
  if (
    contract.template === '' ||
    contract.name === '' ||
    contract.client_name === '' ||
    contract.expires_at === ''
  ) {
    setContractError(true)
    return true;
  }else if(Object.keys(variablesContractualParts).some(key => !variablesContractualParts[key])){
    setContractVariablesError(true)
    return true
  }else if(Object.keys(signContractualParts).some(key => !signContractualParts[key])){
    setContractAnchorsError(true)
    return true
  }

  }


  const saveContract = () => {

    if(validateContractData()) return

    setIsSaving(true);
    ORBApi.postContracts(contract)
      .then(() => {
        toast.success('Contrato Salvo!');
        props.onSaveContract();
        props.setFormVisible(false);
      })
      .catch((error) => {
        errorHandler(error);
        
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const errorHandler = (error) => {
    switch (error.response.data.error) {
      case 'CONTRACT_EXISTS':
        toast.warn('O contrato já existe');
        break;
      case 'INVALID_DOCUMENT':
        toast.warn('Utilize um documento válido');
        break;
      case 'MISSING_VARIABLES':
        setContractVariablesError(true)
        break;
      case 'MISMATCH_CONTRACTUAL_PART_VARIABLE':
        toast.warn(
          `${error.response.data.description.part} não possui ${error.response.data.description.variable}`,
        );
        break;
      case 'TEMPLATE_NOT_FOUND':
        toast.warn('Modelo não encontrado');
        break;
        case 'MISSING_SIGN_ANCHOR':
          setContractAnchorsError(true)
          break;
      default:

        toast.warn('Erro ao cadastrar contrato');
        break;
    }
  };
  
  function updateSignStrings(value, sign) {
    const { id: partId } = value;
    const signStrings = [...contract.sign_strings];
  
    signStrings.forEach((part, index) => {
      if (part.anchors.includes(sign)) {
        part.anchors = part.anchors.filter(anchor => anchor !== sign);
        if (part.anchors.length === 0) {
          signStrings.splice(index, 1);
        }
      }
    });
  
    const existingIndex = signStrings.findIndex(obj => obj.part === partId);
    if (existingIndex !== -1) {
      signStrings[existingIndex].anchors.push(sign);
    } else {
      signStrings.push({ part: partId, anchors: [sign] });
    }
  
    setContract({ ...contract, sign_strings: signStrings });
  }

  const handleChange = (event, newValue) => {
    setShowContent(false); 
    setValue(newValue); //
    setTimeout(() => {
      setShowContent(true); 
    }, 450);
  };
  const validateDate = (dateString) => {
    if (!dateString || !moment(dateString, 'YYYY-MM-DD', true).isValid() || moment(dateString).isBefore(moment(), 'day')) {
      return true
    }else{
      return false
    }
  }
  
  useEffect(() => {
    setTitle('Criar Contrato');
  }, [contract]);

  useEffect(() => {
    if (Object.keys(signContractualParts).every(key => signContractualParts[key])) {
      setContractAnchorsError(false)
    }
    
    if (Object.keys(variablesContractualParts).every(key => variablesContractualParts[key])) {
      setContractVariablesError(false)
    }
    if(Object.keys(contract.variables).every(key => variables[key])){

    }

  }, [variablesContractualParts, signContractualParts])

  useEffect(() => {
    loadVariables(templateId);
  }, [templateId]);

  moment.locale('pt-br');
  return (
    <>
      <h4>{title}</h4>
      <hr />

      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            error={contractError && !contract.name}
            onChange={(e) =>
              setContract({
                ...contract,
                name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Cliente"
            variant="outlined"
            fullWidth
            error={contractError && !contract.client_name}
            onChange={(e) =>
              setContract({
                ...contract,
                client_name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              style={{ width: '100%' }}
              label="Dt Expiração"
              inputFormat="dd/MM/yyyy"
              value={dateValue}
              onChange={(value) => {
                setDateValue(value);
                setContract({
                  ...contract,
                  expires_at: moment(value).format('YYYY-MM-DD'),
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={contractError && validateDate(contract.expires_at)}
                  fullWidth
                  style={{ width: '100%', marginTop: '5px' }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <TemplatesDropdown
            onChange={(template) => {
              setContract({
                ...contract,
                template: template.name,
              });
              setTemplateId(template.id);
            }}
          />
        </Grid>
  
        {variables || signStrings ? (
      
          loading ? <Loading size="25px" /> : (
            <>
            <Paper square sx={{ width: '100%', margin: '10px 2px 10px 10px' }}>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 
                      (contractVariablesError && value === 0) || (contractAnchorsError && value === 1) 
                      ? "red" 
                      : "primary",
                  },
                }}
              >
                <Tab value={0} label={
                  <div className='tabContainer' style={{color: contractVariablesError? "red" : "inherit"}}>
                    <span>Dados do contrato</span>
                    {contractVariablesError ? 
                      <Tooltip arrow placement="top" title="Preencha todos os campos" >
                        <AlertIcon className="error-icon"/> 
                      </Tooltip>
                    : null}
                  </div>
                }/>
                {signStrings.length > 0?     
                  <Tab value={1} label={
                    <div className='tabContainer' style={{color: contractAnchorsError? "red" : "inherit"}}>
                      <span>Assinaturas</span>
                      {contractAnchorsError ? 
                        <Tooltip arrow placement="top" title="Selecione todas as partes para prosseguir" >
                          <AlertIcon className="error-icon"/> 
                        </Tooltip>
                      : null}
                    </div>
                  }/>
                  : null
                }
            
              </Tabs>
            </Paper>

            <Paper square sx={{ width: '100%', margin: '10px 2px 10px 10px', padding:"10px" }}>
            <Slide direction="left" in={showContent} mountOnEnter>
              <Grid container spacing={1} alignItems="center">
            
                {value === 0 ? 
                variables.map((variable) => {
                  const parts = variable.split('__');
                  const label = parts.length > 1 ? parts[1].split('_').join(' ') : variable
    
                  if (variable.startsWith('pc')) {
                    return (
                      <Grid key={variable} item xs={6}>
                        <SuggestContractualParts
                          label={label}
                          error={contractVariablesError}
                          value={variablesContractualParts[variable] || ""}
                          onChange={(value) =>{
                            setVariablesContractualParts({...variablesContractualParts, [variable]: value.name})
                            setContract({
                              ...contract,
                              variables: {
                                ...contract.variables,
                                [variable]: value.id,
                              },
                            })}
                          }
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid key={variable} item xs={6}>
                        <TextField
                          label={variable}
                          variant="outlined"
                          fullWidth
                          value={variablesContractualParts[variable] || ""}
                          error={contractVariablesError && !variablesContractualParts[variable]}
                          onChange={(e) =>{
                            setVariablesContractualParts({...variablesContractualParts, [variable]: e.target.value.toUpperCase()})
                            setContract({
                              ...contract,
                              variables: {
                                ...contract.variables,
                                [variable]: e.target.value.toUpperCase(),
                              },
                            })}
                          }
                        />
                      </Grid>
                    );
                  }
                }) : null}

                {value === 1 ?
                  signStrings.map((sign) => {
                    const label = sign.split("_").join(" ")
                    return(
                      <Grid key={sign} item xs={6}>
                      <SuggestContractualParts
                        error={contractAnchorsError}
                        label={label}
                        value={signContractualParts[sign] || ""}
                        onChange={(value) => {
                          setSignContractualParts({...signContractualParts, [sign]: value.name})
                          if(value.id){
                            setSignContractualPartsId({...signContractualParts, [sign]: value.id});
                          }
                          updateSignStrings(value, sign);
                        }}
                      />
                    </Grid>
                    )

                  })
                : null}

                </Grid>
              </Slide>
            </Paper>
            </>
          ) 
        ) : null}
   
      </Grid>

      <Grid>
        {!isSaving ? (
          <Button
            style={{ marginTop: '15px', backgroundColor: 'rgb(245, 0, 87)' }}
            variant="contained"
            size="large"
            onClick={saveContract}
          >
            {' '}
            Salvar{' '}
          </Button>
        ) : (
          <div>
            <Loading size="25px" text="Salvando Contrato" />
          </div>
        )}
      </Grid>
    </>
  );
};

export default ContractsForm;
