import React, { useEffect, useState } from 'react';
import ORBNavBar from '../../components/ORBNavBar';
import ORBApi from '../../Services/ORBApi';
import Loading from '../../components/common/Loading';
import ActionBar, { defaultFilters } from './components/ActionBar';
import './style.css';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import ContractsTable from './components/ContractsTable';
import ContractInformations from './components/ContractInformations';

const Contratos = () => {
  const [data, setData] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [informationsVisible, setInformationsVisible] = useState(false);
  const [contractOnPlay, setContractOnPlay] = useState(null);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const onSaveContract = () => {
    setFormVisible(false);
    loadData(filters.page);
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const showContractInformations = (contract) => {
    if (contract) setContractOnPlay(JSON.parse(JSON.stringify(contract)));
    // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
    else setContractOnPlay(null);
    setInformationsVisible(true);
  };

  const loadData = (page) => {
    ORBApi.loadContracts(page, filters).then((data) => {
      setData(data);
    });
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  return (
    <>
      <div className="contracts-page">
        <ORBNavBar title="Contratos" />
        <ActionBar
          formVisible={formVisible}
          setFormVisible={setFormVisible}
          onFilterChange={onFilterChangeHandler}
          onSaveContract={onSaveContract}
        />

        {!data ? (
          <Loading text="Buscando Contratos ..." />
        ) : (
          <>
            <ContractsTable
              total_items={data.items_count}
              current_page={data.current_page}
              pages={data.total_pages}
              contracts={data.contracts}
              onPageChange={onPageChangeHandler}
              showContractInformations={showContractInformations}
              loadData={loadData}
            />
            <div>
              <Modal
                size="lg"
                open={informationsVisible}
                onClose={() => {
                  setInformationsVisible(!informationsVisible);
                  // setTemplateOnPlay(null);
                }}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeogut: 500 }}
              >
                <div style={{ width: '80%' }} className="modal-container">
                  <ContractInformations contract_data={contractOnPlay} />
                </div>
              </Modal>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Contratos;
