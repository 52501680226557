import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import config from '../../config';

export default function Login() {
  let [queryString, setQueryString] = useSearchParams();
  let navigate = useNavigate();
  let userData = React.useContext(UserContext);

  useEffect(() => {
    // get the session token from the URL
    let token = queryString.get('auth_token');
    if (token) {
      window.localStorage.setItem('auth_token', token);
    } else {
      token = window.localStorage.getItem('auth_token');
    }
    // if got the token from URL or localStorage, mount the session
    if (token) {
      userData.mountSession().then((success) => {
        if (success) {
          navigate('/'); // send the user to the root
        } else {
          window.location.href = config.orb_auth_url;
        }
      });
    } else {
      window.location.href = config.orb_auth_url;
    }
  }, []);
  return <></>;
}
