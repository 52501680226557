import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

export const ContractualPartsInputsPj = ({
  handleChange,
  contractualPartsPj,
}) => {
  return (
    <Grid sx={{ padding: '15px 0' }} container spacing={2} alignItems="center">
      <Grid item xs={6} md={4}>
        <TextField
          label="Empresa"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.company}
          onChange={(e) =>
            handleChange('company', e.target.value.toUpperCase(), '')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="CNPJ"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.cnpj}
          onChange={(e) =>
            handleChange('cnpj', e.target.value.toUpperCase(), '')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.email}
          onChange={(e) =>
            handleChange('email', e.target.value.toUpperCase(), '')
          }
        />
      </Grid>

      <h4
        style={{
          display: 'block',
          width: '100%',
          textAlign: 'left',
          margin: '25px 0 5px 18px',
        }}
      >
        Endereço
      </h4>

      <Grid item xs={6} md={4}>
        <TextField
          label="Rua"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.address.street}
          onChange={(e) =>
            handleChange('street', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Número"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.address.number}
          onChange={(e) =>
            handleChange('number', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Complemento"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.address.complement}
          onChange={(e) =>
            handleChange('complement', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Bairro"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.address.neighborhood}
          onChange={(e) =>
            handleChange(
              'neighborhood',
              e.target.value.toUpperCase(),
              'address',
            )
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Cidade"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.address.city}
          onChange={(e) =>
            handleChange('city', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Estado"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.address.state}
          onChange={(e) =>
            handleChange('state', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="CEP"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.address.cep}
          onChange={(e) =>
            handleChange('cep', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <h4
        style={{
          display: 'block',
          width: '100%',
          textAlign: 'left',
          margin: '25px 0 5px 18px',
        }}
      >
        Dados bancários
      </h4>
      <Grid item xs={6} md={4}>
        <TextField
          label="Banco"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.bank_data.bank}
          onChange={(e) =>
            handleChange('bank', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Empresa"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.bank_data.company}
          onChange={(e) =>
            handleChange('company', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="CNPJ"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.bank_data.cnpj}
          onChange={(e) =>
            handleChange('cnpj', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>

      <Grid item xs={6} md={4}>
        <TextField
          label="Conta"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.bank_data.account}
          onChange={(e) =>
            handleChange('account', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Agência"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.bank_data.agency}
          onChange={(e) =>
            handleChange('agency', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Pix"
          variant="outlined"
          fullWidth
          value={contractualPartsPj.bank_data.pix}
          onChange={(e) =>
            handleChange('pix', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
    </Grid>
  );
};

export const ContractualPartsInputsPf = ({
  handleChange,
  contractualPartsPf,
}) => {
  return (
    <Grid sx={{ padding: '15px 0' }} container spacing={2} alignItems="center">
      <Grid item xs={6} md={4}>
        <TextField
          label="Nome Completo"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.full_name}
          onChange={(e) =>
            handleChange('full_name', e.target.value.toUpperCase(), '')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="CPF"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.cpf}
          onChange={(e) =>
            handleChange('cpf', e.target.value.toUpperCase(), '')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="RG"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.rg}
          onChange={(e) => handleChange('rg', e.target.value.toUpperCase(), '')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.email}
          onChange={(e) => handleChange('email', e.target.value.toUpperCase(), '')}
        />
      </Grid>
      <h4
        style={{
          display: 'block',
          width: '100%',
          textAlign: 'left',
          margin: '25px 0 5px 18px',
        }}
      >
        Endereço
      </h4>
      <Grid item xs={6} md={4}>
        <TextField
          label="Rua"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.address.street}
          onChange={(e) =>
            handleChange('street', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Número"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.address.number}
          onChange={(e) =>
            handleChange('number', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Complemento"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.address.complement}
          onChange={(e) =>
            handleChange('complement', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Bairro"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.address.neighborhood}
          onChange={(e) =>
            handleChange(
              'neighborhood',
              e.target.value.toUpperCase(),
              'address',
            )
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Cidade"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.address.city}
          onChange={(e) =>
            handleChange('city', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Estado"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.address.state}
          onChange={(e) =>
            handleChange('state', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="CEP"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.address.cep}
          onChange={(e) =>
            handleChange('cep', e.target.value.toUpperCase(), 'address')
          }
        />
      </Grid>

      <h4
        style={{
          display: 'block',
          width: '100%',
          textAlign: 'left',
          margin: '25px 0 5px 18px',
        }}
      >
        Dados bancários
      </h4>

      <Grid item xs={6} md={4}>
        <TextField
          label="Banco"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.bank_data.bank}
          onChange={(e) =>
            handleChange('bank', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Nome Completo"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.bank_data.full_name}
          onChange={(e) =>
            handleChange('full_name', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="CPF"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.bank_data.cpf}
          onChange={(e) =>
            handleChange('cpf', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="RG"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.bank_data.rg}
          onChange={(e) =>
            handleChange('rg', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>

      <Grid item xs={6} md={4}>
        <TextField
          label="Conta"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.bank_data.account}
          onChange={(e) =>
            handleChange('account', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Agência"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.bank_data.agency}
          onChange={(e) =>
            handleChange('agency', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Pix"
          variant="outlined"
          fullWidth
          value={contractualPartsPf.bank_data.pix}
          onChange={(e) =>
            handleChange('pix', e.target.value.toUpperCase(), 'bank_data')
          }
        />
      </Grid>
    </Grid>
  );
};
