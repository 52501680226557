import React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ContractTableDownloadIcon from './ContractTableDownloadIcon';

const ContractTableDownloadIconPdf = ({
  contract_id,
  contract_name,
  isSigned,
}) => {
  return (
    <ContractTableDownloadIcon
      contract_id={contract_id}
      contract_name={contract_name}
      fileType="pdf"
      icon={<PictureAsPdfIcon sx={{color:"red"}} />}
      mimeType="application/pdf"
      isSigned={isSigned}
    />
  );
};

export default ContractTableDownloadIconPdf;
