import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = config.api_base_url;

export default class ORBApi {
  static _doGet(url, responseType, abortSource) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    let requestOptions = {};
    if (responseType) requestOptions.responseType = responseType;
    return axios
      .get(url, requestOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/login');
        }
        throw error;
      });
  }

  static _doPost(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .post(url, data, { headers: { 'content-type': contentType } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/login');
        }
        throw error;
      });
  }
  static _doPut(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .put(url, data, { headers: { 'content-type': contentType } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }
  static _doPatch(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .patch(url, data, { headers: { 'content-type': contentType } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static _doDelete(url, responseType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    let requestOptions = {};
    if (responseType) requestOptions.responseType = responseType;
    return axios
      .delete(url, requestOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/login');
        }
        throw error;
      });
  }

  // TEMPLATES

  static async loadTemplatesOptions(page = 1, page_size = 0) {
    return await ORBApi._doGet(
      `private/templates?page=${page}&page_size=${page_size}`,
    );
  }
  static async loadTemplates(page = 1, filters) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);

    if (filters.name) {
      params.push(`search_term=${filters.name}`);
    }
    if (filters.contract_type) {
      params.push(`contract_type=${filters.contract_type}`);
    }
    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(`private/templates${url_params}`);
  }
  static async postTemplates(formData, type) {
    return await ORBApi._doPost(`private/templates`, formData, type);
  }
  static async patchTemplates(id, formData, type) {
    return await ORBApi._doPatch(`private/templates/${id}`, formData, type);
  }
  static async downloadTemplate(id) {
    return await ORBApi._doGet(`private/templates/${id}/download`, 'blob');
  }

  static async loadTemplateDetails(id) {
    return await ORBApi._doGet(`private/templates/${id}`);
  }

  static async deleteTemplate(id) {
    return await ORBApi._doDelete(`private/templates/${id}`);
  }
  // CONTRATOS

  static async loadContracts(page = 1, filters) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    if (filters.template_name) {
      params.push(`template=${filters.template_name}`);
    }
    if (filters.search_term) {
      params.push(`search_term=${filters.search_term}`);
    }
    if (filters.contract_type) {
      params.push(`contract_type=${filters.contract_type}`);
    }
    if (filters.status) {
      params.push(`status=${filters.status}`);
    }
    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }
    return await ORBApi._doGet(`private/contracts${url_params}`);
  }

  static async postContracts(body) {
    return await ORBApi._doPost(`private/contracts`, body);
  }

  static async downloadContractPdf(id) {
    return await ORBApi._doGet(`private/contracts/${id}/download`, 'blob');
  }
  static async downloadContractDocx(id) {
    return await ORBApi._doGet(`private/contracts/${id}/download/docx`, 'blob');
  }

  static async downloadContractExcel(id) {
    return await ORBApi._doGet(
      `/private/contracts/${id}/download/excel`,
      'blob',
    );
  }

  static async downloadContractFile(id, type) {
    var url = `/private/contracts/${id}/download`;

    if (type === 'xlsx') {
      url += '/excel';
    } else if (type === 'docx') {
      url += '/docx';
    }

    return await ORBApi._doGet(url, 'blob');
  }

  static async deleteContract(id) {
    return await ORBApi._doDelete(`private/contracts/${id}`);
  }

  static async submitContractSigning(id) {
    return await ORBApi._doPatch(
      `private/contracts/${id}/sign`);
  }

  // TIPOS DE CONTRATO

  static async loadContractTypeOptions(page = 1, page_size = 0) {
    return await ORBApi._doGet(
      `private/contract/types?page=${page}&page_size=${page_size}`,
    );
  }

  static async loadContractTypes(page = 1, filters) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    if (filters.type) {
      params.push(`type=${filters.type}`);
    }

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(`private/contract/types${url_params}`);
  }
  static async postContractType(body) {
    return await ORBApi._doPost(`private/contract/types`, body);
  }
  static async putContractType(body) {
    return await ORBApi._doPut(`private/contract/types/${body.id}`, body);
  }
  static async loadRequiredVariables(id) {
    return await ORBApi._doGet(`private/contract/types/${id}`);
  }

  static async deleteContractType(id) {
    return await ORBApi._doDelete(`private/contract/types/${id}`);
  }

  static async loadContractTypesDropdown() {
    return await ORBApi._doGet(`private/contract/types?page_size=0`);
  }

  // PARTES CONTRATUAIS

  static async loadContractualParts(page = 1, entity_type, page_size = 8) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    params.push(`page_size=${page_size}`);
    params.push(`entity_type=${entity_type}`);

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(`private/contractual-parts${url_params}`);
  }

  static async loadSuggestContractualParts(page = 1, filters, page_size = 8) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    params.push(`page_size=${page_size}`);
    if (filters.name) params.push(`entity_name=${filters.name}`);

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(`private/contractual-parts${url_params}`);
  }

  static async postContractualPartsPj(body) {
    return await ORBApi._doPost(`private/contractual-parts/pj`, body);
  }

  static async postContractualPartsPf(body) {
    return await ORBApi._doPost(`private/contractual-parts/pf`, body);
  }

  static async putContractualPartsPj(id, body) {
    return await ORBApi._doPut(`private/contractual-parts/${id}/pj`, body);
  }

  static async putContractualPartsPf(id, body) {
    return await ORBApi._doPut(`private/contractual-parts/${id}/pf`, body);
  }

  static async getUser() {
    return await ORBApi._doGet(`private/sessions`);
  }
}
