import React, { useEffect, useState, useContext } from 'react';
import ORBNavBar from '../../components/ORBNavBar';
import ORBApi from '../../Services/ORBApi';
import Loading from '../../components/common/Loading';
import ActionBar, { defaultFilters } from './components/ActionBar';
import TemplatesTable from './components/TemplatesTable';
import TemplatesForm from './components/TemplatesForm';
import './style.css';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { UserContext } from '../../contexts/UserContext';

const Templates = () => {
  const { userData } = useContext(UserContext);

  const [data, setData] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [templateOnPlay, setTemplateOnPlay] = useState(null);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const onSaveTemplate = () => {
    setFormVisible(false);
    loadData(filters.page);
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const showTemplateForm = (template) => {
    if (template) setTemplateOnPlay(JSON.parse(JSON.stringify(template)));
    // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
    else setTemplateOnPlay(null);
    setFormVisible(true);
  };

  const loadData = (page) => {
    ORBApi.loadTemplates(page, filters).then((data) => {
      setData(data);
    });
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  return (
    <div className="templates-page">
      <ORBNavBar title="Modelos" />
      <ActionBar
        formVisible={formVisible}
        setFormVisible={setFormVisible}
        onFilterChange={onFilterChangeHandler}
        onSaveTemplate={onSaveTemplate}
      />
      {!data ? (
        <Loading text="Buscando Templates ..." />
      ) : (
        <TemplatesTable
          total_items={data.items_count}
          current_page={data.current_page}
          pages={data.total_pages}
          templates={data.templates}
          onPageChange={onPageChangeHandler}
          showTemplateForm={showTemplateForm}
          loadData={loadData}
        />
      )}

      <div>
        <Modal
          size="lg"
          open={formVisible}
          onClose={() => {
            setFormVisible(!formVisible);
            setTemplateOnPlay(null);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-container">
            <TemplatesForm
              edit_templates={templateOnPlay}
              onSaveTemplate={onSaveTemplate}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Templates;
