import React, { useEffect, useState } from 'react';
import ORBNavBar from '../../components/ORBNavBar';
import ORBApi from '../../Services/ORBApi';
import ActionBar, { defaultFilters } from './components/ActionBar';
import Loading from '../../components/common/Loading';
import ContractualPartsForm from './components/ContractualPartsForm';
import TabNavigation from './components/TabNavigation';
import './style.css';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';

const ContractualParts = () => {
  const [dataPf, setDataPf] = useState(null);
  const [dataPj, setDataPj] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [ContractualPartsOnPlay, setContractualPartsOnPlay] = useState(null);

  const onSaveContractualParts = () => {
    setFormVisible(false);
    loadDataPj(1);
    loadDataPf(1);
  };

  const onPagePjChangeHandler = (page) => {
    if (page !== dataPj.current_page) loadDataPj(page);
  };
  const onPagePfChangeHandler = (page) => {
    if (page !== dataPf.current_page) loadDataPf(page);
  };

  const showContractualPartsForm = (template) => {
    if (template) {
      setContractualPartsOnPlay(JSON.parse(JSON.stringify(template)));
      // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
    } else setContractualPartsOnPlay(null);
    setFormVisible(true);
  };

  const loadDataPj = (page) => {
    ORBApi.loadContractualParts(page, 'PJ').then((data) => {
      setDataPj(data);
    });
  };
  const loadDataPf = (page) => {
    ORBApi.loadContractualParts(page, 'PF').then((data) => {
      setDataPf(data);
    });
  };

  useEffect(() => {
    loadDataPj(1);
    loadDataPf(1);
  }, []);

  return (
    <div className="contract-type-page">
      <ORBNavBar margin={'0 0 20px 30px'} title=" Partes Contratuais" />
      <ActionBar showContractualPartsForm={showContractualPartsForm} />
      {!dataPj || !dataPf ? (
        <Loading size={'30px'} />
      ) : (
        <TabNavigation
          dataPj={dataPj}
          dataPf={dataPf}
          changePagePj={onPagePjChangeHandler}
          changePagePf={onPagePfChangeHandler}
          showContractualParts={showContractualPartsForm}
        />
      )}

      <div>
        <Modal
          size="lg"
          open={formVisible}
          onClose={() => {
            setFormVisible(!formVisible);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-container">
            <ContractualPartsForm
              edit_contractualParts={ContractualPartsOnPlay}
              onSaveContractualParts={onSaveContractualParts}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ContractualParts;
