import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TextField } from '@mui/material';

export default function DebouncedTextField(props) {
  const debounced = useDebouncedCallback((value) => {
    props.onChange(value);
  }, 500);

  return (
    <TextField
      sx={{ maxWidth: 'md' }}
      variant="outlined"
      label={props.label}
      onChange={(e) => debounced(e.target.value)}
      margin="normal"
    />
  );
}
