import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../components/common/Loading';
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Box,
  InputLabel,
} from '@mui/material';
import ORBApi from '../../../Services/ORBApi';
import RequiredVariablesTable from './RequiredVariablesTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ContractTypesForm = (props) => {
  const [contractType, setContractType] = useState({
    type: '',
    required_variables: [],
  });
  const [requiredVariables, setRequiredVariables] = useState([]);
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loadingRequiredVariables, setLoadingRequiredVariables] =
    useState(false);

  const saveContractType = () => {
    setIsSaving(true);

    if (props.edit_contractType) {
      ORBApi.putContractType(contractType)
        .then(() => {
          props.onSaveContractType();
          toast.success('Edição concluída');
        })
        .catch((err) => {
          errorHandler(err);
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      ORBApi.postContractType(contractType)
        .then(() => {
          props.onSaveContractType();
          toast.success('Tipo de contrato salvo');
        })
        .catch((err) => {
          errorHandler(err);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const errorHandler = (err) => {
    if (err.response.data.error === 'CONTRACT_TYPE_EXISTS') {
      toast.error('O tipo de contrato já existe');
      return;
    }
    if (!contractType.type) {
      toast.warn('O Campo "Tipo de contrato" precisa ser preenchido');
    }
  };

  const loadRequiredVariables = (id) => {
    setLoadingRequiredVariables(true);
    ORBApi.loadRequiredVariables(id)
      .then((variables) => {
        setRequiredVariables(variables.required_variables);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingRequiredVariables(false);
      });
  };

  useEffect(() => {
    if (props.edit_contractType) {
      setTitle('Editar tipos de contratos');
      setContractType(props.edit_contractType);
      loadRequiredVariables(props.edit_contractType.id);
    } else {
      setTitle('Criar tipos de contrato');
    }
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            label="Tipo de contrato"
            variant="outlined"
            fullWidth
            value={contractType?.type}
            onChange={(e) =>
              setContractType({
                ...contractType,
                type: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => {
              setVisible(!visible);
            }}
          >
            Criar campo obrigatório
            {!visible ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Button>
        </Grid>
        {visible ? (
          <RequiredVariables
            contractType={contractType}
            setContractType={setContractType}
            requiredVariables={requiredVariables}
            setRequiredVariables={setRequiredVariables}
            setVisible={setVisible}
          />
        ) : null}
      </Grid>

      {loadingRequiredVariables ? (
        <Loading
          margin={'20px'}
          size={'25px'}
          text={'Carregando campos obrigatórios'}
        />
      ) : requiredVariables.length > 0 ? (
        <Grid sx={{ marginTop: '15px' }}>
          <RequiredVariablesTable data={requiredVariables} />
        </Grid>
      ) : null}

      <Grid>
        {!isSaving ? (
          <Button
            style={{ marginTop: '15px', backgroundColor: 'rgb(245, 0, 87)' }}
            variant="contained"
            size="large"
            onClick={saveContractType}
          >
            {props.edit_contractType ? 'Editar' : 'Salvar'}
          </Button>
        ) : (
          <Loading
            margin={'20px'}
            size={'20px'}
            text={
              props.edit_contractType
                ? 'Editando Tipos de contratos'
                : 'Salvando tipos de contratos'
            }
          />
        )}
      </Grid>
    </>
  );
};
export default ContractTypesForm;

export const RequiredVariables = (props) => {
  const [type, setType] = useState(null);
  const [variable, setVariable] = useState('');
  const [partName, setPartName] = useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleVariableChange = (event) => {
    setVariable(event.target.value);
  };

  const handlePartNameChange = (event) => {
    setPartName(event.target.value);
  };

  const handleAddVariable = () => {
    if (type === 'Texto') {
      if (!variable) {
        toast.warn('Preencha todos os campos');
        return;
      }
      props.setContractType({
        ...props.contractType,
        required_variables: [
          ...props.contractType.required_variables,
          { type: type, variable: variable },
        ],
      });
    } else if (type === 'Parte contratual') {
      if (!variable || !partName) {
        toast.warn('Preencha todos os campos');
        return;
      }
      props.setContractType({
        ...props.contractType,
        required_variables: [
          ...props.contractType.required_variables,
          { type: type, variable: variable, part_name: partName },
        ],
      });
    }
    props.setRequiredVariables([
      ...props.requiredVariables,
      { type: type, variable: variable, part_name: partName },
    ]);

    setType(null);
    setVariable('');
    setPartName('');
  };

  return (
    <>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="type"
            onChange={handleChange}
          >
            <MenuItem value={'Texto'}>Texto</MenuItem>
            <MenuItem value={'Parte contratual'}>Parte Contratual</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12}></Grid>
      {type === 'Texto' ? (
        <>
          {' '}
          <Grid item xs={4}>
            <TextField
              label="Campo obrigatório"
              variant="outlined"
              fullWidth
              value={variable}
              onChange={handleVariableChange}
            />
          </Grid>
        </>
      ) : null}
      {type === 'Parte contratual' ? (
        <>
          <Grid item xs={4}>
            <TextField
              label="Nome da parte"
              variant="outlined"
              fullWidth
              value={partName}
              onChange={handlePartNameChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Campo"
              variant="outlined"
              fullWidth
              value={variable}
              onChange={handleVariableChange}
            />
          </Grid>
        </>
      ) : null}
      {type !== null ? (
        <Grid item xs={3}>
          <Button onClick={handleAddVariable}>Adicionar</Button>
        </Grid>
      ) : null}
    </>
  );
};
