import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const RequiredVariablesTable = ({ data, onPageChange }) => {
  return (
    <>
      <TableContainer>
        <Table size="small" className="basic-table">
          <TableHead className="basic-table-head">
            <TableRow>
              {['Tipo', 'Campo', 'Nome da parte'].map((title) => (
                <TableCell align="center">{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((data, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{data.type}</TableCell>
                  <TableCell align="center">{data.variable}</TableCell>
                  <TableCell align="center">{data.part_name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Pagination
        shape="rounded"
        className="table-pagination"
        count={data.pages}
        page={data.current_page}
        onChange={(e, value) => {
          onPageChange(value);
        }}
      /> */}
    </>
  );
};

export default RequiredVariablesTable;
