import React, { useState } from 'react';
import ContractualPartsTable from './ContractualPartsTable';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabNavigation({
  dataPj,
  dataPf,
  changePagePf,
  changePagePj,
  showContractualParts,
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            marginright: 'auto',
            marginLeft: 'auto',
          }}
        >
          <Tabs
            style={{
              width: '95%',

              margin: '40px auto 0 auto',
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="PJ" {...a11yProps(0)} />
            <Tab label="PF" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ContractualPartsTable
            total_items={dataPj.items_count}
            current_page={dataPj.current_page}
            pages={dataPj.total_pages}
            contractual_parts={dataPj.parts}
            onPageChange={changePagePj}
            showContractualParts={showContractualParts}
            personType={'PJ'}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ContractualPartsTable
            total_items={dataPf.items_count}
            current_page={dataPf.current_page}
            pages={dataPf.total_pages}
            contractual_parts={dataPf.parts}
            onPageChange={changePagePf}
            showContractualParts={showContractualParts}
            personType={'PF'}
          />
        </CustomTabPanel>
      </Box>{' '}
    </>
  );
}

export default TabNavigation;
