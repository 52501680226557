import React, { useState, useEffect } from 'react';
import ORBApi from '../../../Services/ORBApi';
import Loading from '../../../components/common/Loading';
import { toast } from 'react-toastify';

const ContractTableDownloadIcon = ({
  contract_id,
  contract_name,
  fileType,
  icon,
  mimeType,
  isSigned,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [hoverMessage, setHoverMessage] = useState('');

  const handleHover = () => {
    switch (fileType) {
      case 'pdf':
        isSigned
          ? setHoverMessage('Contrato Assinado')
          : setHoverMessage('Contrato em pdf');
        break;
      case 'docx':
        setHoverMessage('Contrato em docx');
        break;
      case 'xlsx':
        setHoverMessage('Variáveis do contrato');
        break;
      default:
        setHoverMessage('');
    }
  };

  const downloadFile = (contract_id, contract_name) => {
    setLoading(true);

    ORBApi.downloadContractFile(contract_id, fileType)
      .then((data) => {
        const file = new Blob([data], {
          type: mimeType,
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `${contract_name}.${fileType}`;
        link.click();
      })
      .catch(() => toast.error('Falha ao baixar contrato'))
      .finally(() => setLoading(false));
  };

  return (
    <div
      className="download-file clickable"
      onMouseEnter={handleHover}
      onMouseLeave={() => setHoverMessage('')}
      style={{ position: 'relative' }}
      onClick={() => {
        downloadFile(contract_id, contract_name);
      }}
    >
      {isLoading ? (
        <Loading size="18px" />
      ) : (
        <>
          {icon}
          {hoverMessage && <div className="hover-message">{hoverMessage}</div>}
        </>
      )}
    </div>
  );
};

export default ContractTableDownloadIcon;
