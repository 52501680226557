import React from "react";
import DocxIcon from '../../../icons/docx.svg';
import ContractTableDownloadIcon from "./ContractTableDownloadIcon";

const ContractTableDownloadIconDocx = ({ contract_id, contract_name }) => {
    return (
        <ContractTableDownloadIcon
            contract_id={contract_id}
            contract_name={contract_name}
            fileType="docx"
            icon={<img style={{maxWidth:'20px',marginBottom:'5px'}} src={DocxIcon} alt="Ícone de Documento"/>}
            mimeType="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
    );
};

export default ContractTableDownloadIconDocx;