import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loading({ margin, className, text, size }) {
  return (
    <Box
      className={className}
      sx={{ display: 'flex', margin: margin ? margin : 0 }}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color="inherit" size={size} />
      {text ? <h4>{text}</h4> : null}
    </Box>
  );
}
