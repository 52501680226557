import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import ORBApi from '../../../Services/ORBApi';
import Loading from '../../../components/common/Loading';
const DeleteTemplateModal = (props) => {
  const [deleting, setDeleting] = useState(false);

  const deleteContract = (id) => {
    setDeleting(true);
    ORBApi.deleteContract(id)
      .then(() => {
        toast.success('Contrato excluido com sucesso!');
      })
      .catch(() => {
        toast.error('Falha ao excluir.');
      })
      .finally(() => {
        setDeleting(false);
        props.setVisible(false);
        props.loadData(1);
      });
  };

  return (
    <>
      <h3 style={{ textAlign: 'center' }}>Deseja deletar este contrato ?</h3>
      <div
        style={{ display: 'flex', margin: '0 auto', justifyContent: 'center' }}
      >
        {deleting ? (
          <Loading />
        ) : (
          <>
            <Button
              style={{
                marginTop: '15px',
                backgroundColor: 'rgb(245, 0, 87)',
                marginRight: '10px',
              }}
              variant="contained"
              size="large"
              onClick={() => {
                deleteContract(props.contractId);
              }}
            >
              {' '}
              Sim, deletar{' '}
            </Button>
            <Button
              style={{ marginTop: '15px', backgroundColor: '#d3d3d3' }}
              variant="contained"
              size="large"
              onClick={() => {
                props.setVisible(false);
              }}
            >
              {' '}
              Não, cancelar{' '}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default DeleteTemplateModal;
