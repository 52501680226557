import React, { useEffect, useState } from 'react';
import ORBApi from '../Services/ORBApi';
import { Select, InputLabel, FormControl } from '@mui/material';

const ContractTypeDropdown = (props) => {
  const [contractTypes, setContractTypes] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    ORBApi.loadContractTypeOptions().then((response) => {
      setContractTypes(response.types);
    });
  }, []);

  const onChangeHandler = (e) => {
    setSelected(e.target.value);
    props.onChange({
      id: e.target.value,
      name: e.target.selectedOptions[0].text,
    });
  };

  return (
    <FormControl style={{ width: '100%' }} variant="outlined" margin="normal">
      <InputLabel>Tipo de contrato</InputLabel>
      <Select
        native
        label="Modelo"
        onChange={onChangeHandler}
        value={selected}
        notched={selected > 0}
      >
        <option value="" />
        {contractTypes.map((contractType) => {
          return (
            <option key={contractType.id} value={contractType.id}>
              {contractType.type}
            </option>
          );
        })}
        ;
      </Select>
    </FormControl>
  );
};

export default ContractTypeDropdown;
