import React, { useState, useEffect, createContext } from 'react';
import ORBApi from '../Services/ORBApi';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const mountSession = () => {

    return ORBApi.getUser().then((response) => {
      setUser(response);
      return true;
    }).catch(() => { return false;});
  }
  return (
    <UserContext.Provider value={{ user, mountSession }}>{children}</UserContext.Provider>
  );
};
