import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ORBApi from '../../../Services/ORBApi';
import { toast } from 'react-toastify';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import DeleteContractModal from './DeleteContractModal';
import ContractTableDownloadIconPdf from './ContractTableDownloadIconPdf';
import ContractTableDownloadIconDocx from './ContractTableDownloadIconDocx';
import ContractTableDownloadIconExcel from './ContractTableDownloadIconExcel';
import Loading from '../../../components/common/Loading';
import PendingIcon from '../../../icons/pending-icon';

const ContractsTable = (props) => {
  const [deleteId, setDeleteId] = useState();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(0);
  const [contractId, setContractId] = useState(null);

  moment.locale('pt-br');


  const onStatusChange = (status, contract_id) => {
    switch (status) {
      case "SIGNED":
        return (
          <Tooltip title="Assinado" placement="top" arrow>
            <CheckIcon />
          </Tooltip>
        );
      case "UNSIGNED":
        return (
            <button
              className="clickable sign-button"
              onClick={() => {handleButtonClick(contract_id)}}
            >
              Assinar
            </button>
        );
      case "PENDING":
        return (
          <Tooltip title="Assinatura pendente" placement="top" arrow>
            <div>
              <PendingIcon className="pending-icon"/>
            </div>
          </Tooltip>
        )
      default:
        return null;
    }
  };

  
  const handleButtonClick = (id) => {
    setContractId(id);
  };

  useEffect(() => {
    if(contractId){
    setLoading(1)
    ORBApi.submitContractSigning(contractId)
      .then(() => {
        toast.success('Contrato atualizado!');
        props.loadData(props.current_page);
      })
      .catch((error) => {
        toast.warning('Erro ao assinar contrato !');
      })
      .finally(() => {
        setContractId(null)
        setLoading(0);
      });}
  }, [contractId]);


  return (
    <>
      <TableContainer component={Paper}>
        <Table className="page-table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'rgb(0, 34, 67)' }}>
              {[
                'Nome',
                'Modelo',
                'Cliente',
                'Dt Expiração',
                'Tipo de contrato',
                'Contrato',
                'Status',
                '',
              ].map((title, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ color: '#F5F5F5' }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.contracts.length > 0
              ? props.contracts.map((contract, key) => {
                  return (
                    <TableRow key={key} className="row">
                      <TableCell
                        className="cell clickable"
                        align="center"
                        onClick={() => {
                          props.showContractInformations(contract);
                        }}
                      >
                        {contract.name}{' '}
                      </TableCell>
                      <TableCell
                        className="cell clickable"
                        align="center"
                        onClick={() => {
                          props.showContractInformations(contract);
                        }}
                      >
                        {contract.template}{' '}
                      </TableCell>
                      <TableCell
                        className="cell clickable"
                        align="center"
                        onClick={() => {
                          props.showContractInformations(contract);
                        }}
                      >
                        {contract.client_name}{' '}
                      </TableCell>
                      <TableCell
                        className="cell clickable"
                        align="center"
                        onClick={() => {
                          props.showContractInformations(contract);
                        }}
                      >
                        {moment(contract.expires_at).format('DD/MM/YYYY')}{' '}
                      </TableCell>
                      <TableCell align="center">
                        {contract.contract_type}
                      </TableCell>
                      <TableCell
                        className="cell"
                        style={{ maxWidth: 60 }}
                        align="center"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {contract.file && (
                            <ContractTableDownloadIconPdf
                              contract_id={contract.id}
                              contract_name={contract.name}
                              isSigned={
                                contract.status === 'SIGNED' ? true : false
                              }
                            />
                          )}
                          {contract.contract_docx && (
                            <ContractTableDownloadIconDocx
                              contract_id={contract.id}
                              contract_name={contract.name}
                            />
                          )}
                          <ContractTableDownloadIconExcel
                            contract_id={contract.id}
                            contract_name={contract.name}
                          />
                        </div>
                      </TableCell>
                      <TableCell className="cell" align="center">
                        <div style={{ width: '100%', position: 'relative' }}>
                          {
                            loading && contractId === contract.id ? 
                            (
                              <Loading size="18px" />
                            ) : 
                              onStatusChange(contract.status, contract.id)
                          }
                        </div>
                      </TableCell>
                      <TableCell className="cell" align="center">
                        <div
                          className=" clickable"
                          onClick={() => {
                            setVisible(true);
                            setDeleteId(contract.id);
                          }}
                        >
                          {contract.file ? <Tooltip title="Apagar contrato" placement='top' arrow><DeleteIcon /></Tooltip> : null}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />

      <div>
        <Modal
          open={visible}
          onClose={() => {
            setVisible(false);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-delete-template">
            <DeleteContractModal
              setVisible={setVisible}
              contractId={deleteId}
              loadData={props.loadData}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ContractsTable;
