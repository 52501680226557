import React, { useState } from 'react';
import FilterIcon from '../../../icons/FilterIcon';
import PlusIcon from '../../../icons/PlusIcon';
import { Modal, Backdrop } from '@mui/material';
import DebouncedTextField from '../../../components/common/DebouncedTextField';
import ContractTypesForm from './ContractTypesForm';

export const defaultFilters = {
  type: '',
};

const ActionBar = (props) => {
  const [showFilters, showHideFilters] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onTypeChangeHandler = (type) => {
    updateFilters({ ...filters, type: type });
  };

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        <div
          className="page-actionbar-item action-btn"
          onClick={() => {
            props.setFormVisible(true);
          }}
        >
          <PlusIcon />
        </div>
        <div>
          <Modal
            size="lg"
            open={props.formVisible}
            onClose={() => props.setFormVisible(!props.formVisible)}
          >
            <div className="modal-container">
              <ContractTypesForm
                setFormVisible={props.setFormVisible}
                onSaveContractType={props.onSaveContractType}
              />
            </div>
          </Modal>
        </div>
      </div>
      {showFilters ? (
        <div className="page-filter-bar">
          <DebouncedTextField
            label="tipo de contrato"
            onChange={(value) => {
              onTypeChangeHandler(value.toUpperCase());
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
