import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ORBApi from '../../../Services/ORBApi';
import { toast } from 'react-toastify';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Loading from '../../../components/common/Loading';
import { VisibilityRounded } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import DeleteTemplateModal from './DeleteTemplateModal';

const TemplatesTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [visible, setVisible] = useState(false);

  const downloadFile = (id, name) => {
    setLoading(true);
    ORBApi.downloadTemplate(id)
      .then((data) => {
        const file = new Blob([data], {
          type: `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `${name}.docx`;
        link.click();
      })
      .catch(() => toast.error('Falha ao baixar modelo'))
      .finally(() => {
        setLoading(false);
      });
  };
  moment.locale('pt-br');
  return (
    <>
      <TableContainer component={Paper}>
        <Table className="page-table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'rgb(0, 34, 67)' }}>
              {['Nome', , 'Dt Criação', 'Tipo de contrato', 'Arquivo'].map(
                (title, key) => (
                  <TableCell
                    key={key}
                    align="center"
                    style={{ color: '#F5F5F5' }}
                  >
                    {title}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.templates.length > 0
              ? props.templates.map((template) => {
                  return (
                    <TableRow key={template.id} className="row">
                      <TableCell
                        className="cell clickable"
                        align="center"
                        onClick={() => props.showTemplateForm(template)}
                      >
                        {template.name}{' '}
                      </TableCell>
                      <TableCell
                        className="cell clickable"
                        align="center"
                        onClick={() => props.showTemplateForm(template)}
                      >
                        {moment(template.created_at).format('DD/MM/YYYY')}{' '}
                      </TableCell>
                      <TableCell
                        className="cell clickable"
                        align="center"
                        onClick={() => props.showTemplateForm(template)}
                      >
                        {template.contract_type}
                      </TableCell>
                      <TableCell className="cell" align="center">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            className="file clickable"
                            onClick={() =>
                              downloadFile(template.id, template.name)
                            }
                          >
                            {template.file ? (
                              loading ? (
                                <div style={{ marginRight: '15px' }}>
                                  <Loading size="20px" />
                                </div>
                              ) : (
                                <DownloadIcon style={{ marginRight: '15px' }} />
                              )
                            ) : null}
                          </div>
                          <div
                            className=" clickable"
                            onClick={() => {
                              setVisible(true);
                              setDeleteId(template.id);
                            }}
                          >
                            {template.file ? <DeleteIcon /> : null}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />

      <div>
        <Modal
          open={visible}
          onClose={() => {
            setVisible(false);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-delete-template">
            <DeleteTemplateModal
              setVisible={setVisible}
              templateId={deleteId}
              loadData={props.loadData}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TemplatesTable;
