import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';

const StatusDropdown = ({ onStatusChangeHandler }) => {
  return (
    <FormControl margin="normal" style={{ width: '100%' }}>
      <InputLabel style={{ padding: '0 10px' }}>Status do contrato</InputLabel>
      <Select
        native
        onChange={(e) => {
          onStatusChangeHandler(e.target.value);
        }}
        variant="outlined"
      >
        <option aria-label="None" value="" />
        <option value={'UNSIGNED'}>Assinatura pendente</option>
        <option value={'SIGNED'}>Assinado</option>
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;
