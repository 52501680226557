import React, { useEffect, useState } from 'react';
import ORBApi from '../Services/ORBApi';
import { Select, InputLabel, FormControl } from '@mui/material';

export default function ContractTypesDropdown({
  value,
  onChange,
  className,
  hideEmpty,
  margin,
  label,
}) {
  const [contractTypes, setContractTypes] = useState([]);
  const [selected, setSelected] = useState('');

  const onChangeHandler = (e) => {
    setSelected(e.target.value);
    onChange(e.target.selectedOptions[0].text);
  };

  useEffect(() => {
    setSelected(value);
    console.log(value);
  }, [value]);

  useEffect(() => {
    ORBApi.loadContractTypesDropdown().then((response) => {
      setContractTypes(response.types);
      console.log(response);
    });
  }, []);

  return (
    <FormControl
      fullWidth
      margin={margin}
      variant="outlined"
      className={className}
    >
      <InputLabel shrink={selected !== ''}>{label}</InputLabel>
      <Select
        native
        label={label}
        onChange={onChangeHandler}
        value={selected}
        notched={selected !== ''}
      >
        {!hideEmpty ? <option value="" /> : null}
        {contractTypes.map((type) => {
          return (
            <option key={type.id} value={type.type}>
              {type.type}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}
