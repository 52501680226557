import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../assets/logo-branca.png';
import LogoutIcon from '@mui/icons-material/Logout';
import config from '../../config';

import './style.css';

const ORBNavBar = ({ title, margin }) => {
  return (
    <>
      <div className="header">
        <img className="logo" src={logo} alt="Logo ORB" />

        <nav className="menu">
          <NavLink className="menu-item" to="/">
            Modelos
          </NavLink>
          <NavLink className="menu-item" to="/Contratos">
            Contratos
          </NavLink>
          <NavLink className="menu-item" to="/Tipos">
            Tipos de contratos
          </NavLink>
          <NavLink className="menu-item" to="/PartesContratuais">
            Partes Contratuais
          </NavLink>
          <a className="menu-item" href={config.orb_profile_url}>
            Perfil
          </a>
        </nav>

        <a className="menu-item logout" href={config.orb_logout_url}>
          sair{' '}
          <LogoutIcon
            style={{ width: '15px', marginLeft: '5px', marginTop: '4px' }}
          />
        </a>
      </div>
      <h1 style={{ margin: margin }} className="title">
        {title}
      </h1>
    </>
  );
};

export default ORBNavBar;
