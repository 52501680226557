import React, { useState } from 'react';
import FilterIcon from '../../../icons/FilterIcon';
import PlusIcon from '../../../icons/PlusIcon';
import { Modal, Backdrop } from '@mui/material';
import DebouncedTextField from '../../../components/common/DebouncedTextField';
import ContractualPartsForm from './ContractualPartsForm';

export const defaultFilters = {
  type: '',
};

const ActionBar = (props) => {
  // const [showFilters, showHideFilters] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onTypeChangeHandler = (type) => {
    updateFilters({ ...filters, type: type });
  };

  return (
    <>
      <div style={{ padding: '0 20px' }} className="page-actionbar-container">
        {/* <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div> */}
        <div
          className="page-actionbar-item action-btn"
          onClick={() => {
            props.showContractualPartsForm();
          }}
        >
          <PlusIcon />
        </div>
      </div>
      {/* {showFilters ? (
        <div className="page-filter-bar">
          <DebouncedTextField
          label="tipo de contrato"
          onChange={(value) => {
            onTypeChangeHandler(value.toUpperCase());
          }}
        />
        </div>
      ) : null} */}
    </>
  );
};

export default ActionBar;
