import React, { useState, useEffect } from 'react';
import FilterIcon from '../../../icons/FilterIcon';
import PlusIcon from '../../../icons/PlusIcon';
import { Modal, Backdrop } from '@mui/material';
import DebouncedTextField from '../../../components/common/DebouncedTextField';
import ContractsForm from './ContractsForm';
import TemplatesDropdown from './TemplatesDropdown';
import StatusDropdown from './StatusDropdown';
import ContractTypeDropdown from '../../../components/ContractTypeDropdown';

export const defaultFilters = {
  search_term: '',
  template_name: '',
  status: '',
  contract_type: '',
};

const ActionBar = (props) => {
  const [showFilters, showHideFilters] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    props.onFilterChange(newFilters);
  };

  const onNameChangeHandler = (term) => {
    updateFilters({ ...filters, search_term: term });
  };

  const onStatusChangeHandler = (status) => {
    updateFilters({ ...filters, status: status });
  };
  const onContractTypeChangeHandler = (contract_type) => {
    updateFilters({ ...filters, contract_type: contract_type });
  };

  const onTemplatesChangeHandler = (template_name) => {
    updateFilters({ ...filters, template_name: template_name });
  };

  useEffect(() => {}, [filters]);

  return (
    <>
      <div className="page-actionbar-container">
        <div
          className="page-actionbar-item action-btn"
          onClick={() => showHideFilters(!showFilters)}
        >
          <FilterIcon />
        </div>
        <div
          className="page-actionbar-item action-btn"
          onClick={() => {
            props.setFormVisible(true);
          }}
        >
          <PlusIcon />
        </div>
        <div>
          <Modal
            size="lg"
            open={props.formVisible}
            onClose={() => props.setFormVisible(!props.formVisible)}
          >
            <div className="modal-container">
              <ContractsForm
                setFormVisible={props.setFormVisible}
                onSaveContract={props.onSaveContract}
              />
            </div>
          </Modal>
        </div>
      </div>
      {showFilters ? (
        <div className="page-actionbar-container">
          <div className="page-filter-bar">
            <DebouncedTextField
              label="Contrato e Cliente"
              onChange={(value) => {
                onNameChangeHandler(value);
              }}
            />
          </div>
          <div className="page-filter-bar">
            <ContractTypeDropdown
              onChange={(value) => {
                onContractTypeChangeHandler(value.name);
              }}
            />
          </div>
          <div className="page-filter-bar">
            <TemplatesDropdown
              value={filters.id}
              onChange={(template) => {
                onTemplatesChangeHandler(template.name);
              }}
            />
          </div>
          <div className="page-filter-bar">
            <StatusDropdown onStatusChangeHandler={onStatusChangeHandler} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ActionBar;
