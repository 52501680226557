import React, { useState, useEffect } from 'react';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  Box,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Pagination from '@mui/material/Pagination';

const ContractualPartsTable = (props) => {
  const [tableHeaders, setTableHeaders] = useState([]);
  const [bankHeaders, setBankHeaders] = useState([]);

  const getHeaderConfig = (personType) => {
    if (personType === 'PJ') {
      setTableHeaders([
        'Entidade',
        'Empresa',
        'Cnpj',
        'Rua',
        'Número',
        'Complemento',
        'Cidade',
        'Estado',
        'CEP',
      ]);
      setBankHeaders(['Conta', 'Agência', 'Banco', 'Cnpj', 'Empresa', 'Pix']);
    } else if (personType === 'PF') {
      setTableHeaders([
        'Entidade',
        'Nome',
        'CPF',
        'RG',
        'Rua',
        'Número',
        'Complemento',
        'Cidade',
        'Estado',
        'CEP',
      ]);
      setBankHeaders([
        'Conta',
        'Agência',
        'Banco',
        'CPF',
        'Nome Completo',
        'Pix',
      ]);
    }
  };

  useEffect(() => {
    getHeaderConfig(props.personType);
  }, [props.personType]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className="basic-table">
          <TableHead className="basic-table-head">
            <TableRow style={{ backgroundColor: 'rgb(0, 34, 67)' }}>
              <TableCell></TableCell>

              {tableHeaders?.map((title, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ color: '#F5F5F5' }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.contractual_parts?.length > 0
              ? props.contractual_parts.map((parts) => {
                  return (
                    <ContractualPartsRow
                      headers={bankHeaders}
                      key={parts.id}
                      parts={parts}
                      onClick={props.showContractualParts}
                      personType={props.personType}
                    />
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />
    </>
  );
};

export default ContractualPartsTable;

function ContractualPartsRow(props) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <TableRow className="row">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
        {props.personType === 'PJ' ? (
          <>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.entity_type}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.company}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.cnpj}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.street}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.number}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.complement}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.city}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.state}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.cep}{' '}
            </TableCell>
          </>
        ) : props.personType === 'PF' ? (
          <>
            <TableCell
              onClick={() => props.onClick(props.parts)}
              className="cell clickable"
              align="center"
            >
              {props.parts.entity_type}{' '}
            </TableCell>
            <TableCell
              onClick={() => props.onClick(props.parts)}
              className="cell clickable"
              align="center"
            >
              {props.parts.full_name}{' '}
            </TableCell>
            <TableCell
              onClick={() => props.onClick(props.parts)}
              className="cell clickable"
              align="center"
            >
              {props.parts.cpf}{' '}
            </TableCell>
            <TableCell
              onClick={() => props.onClick(props.parts)}
              className="cell clickable"
              align="center"
            >
              {props.parts.rg}{' '}
            </TableCell>
            <TableCell
              onClick={() => props.onClick(props.parts)}
              className="cell clickable"
              align="center"
            >
              {props.parts.address.street}{' '}
            </TableCell>
            <TableCell
              onClick={() => props.onClick(props.parts)}
              className="cell clickable"
              align="center"
            >
              {props.parts.address.number}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.complement}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.city}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.state}{' '}
            </TableCell>
            <TableCell
              className="cell clickable"
              align="center"
              onClick={() => props.onClick(props.parts)}
            >
              {props.parts.address.cep}{' '}
            </TableCell>
          </>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={12}
        >
          <Collapse timeout="auto" in={!collapsed}>
            <Typography
              style={{ margin: '10px 0' }}
              variant="h6"
              gutterBottom
              component="div"
            >
              Dados Bancários
            </Typography>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {props.headers.map((title, key) => (
                      <TableCell key={key} align="center">
                        {title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {props.personType === 'PJ' ? (
                      <>
                        <TableCell className="cell">
                          {props.parts.bank_data.account}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.agency}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.bank}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.cnpj}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.company}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.pix}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className="cell">
                          {props.parts.bank_data.account}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.agency}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.bank}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.cpf}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.full_name}
                        </TableCell>
                        <TableCell className="cell">
                          {props.parts.bank_data.pix}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
