import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Loading from '../../../components/common/Loading';

const ContractInformations = (props) => {
  const [contractInformations, setContractInformations] = useState(null);
  const [variables, setVariables] = useState(null);

  useEffect(() => {
    setContractInformations(props.contract_data);
    setVariables(props.contract_data.variables);
  }, []);

  if (!contractInformations) return null;
  return (
    <>
      <Grid container>
        <Grid xs={12}>
          {' '}
          <Typography align="center" variant="h5">
            Contrato
          </Typography>
        </Grid>
        <div className="horizontalDivider"></div>
        <Grid xs={12}>
          <Typography align="left" variant="subtitle1">
            <b>Nome do contrato:</b> {contractInformations.name}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography align="left" variant="subtitle1">
            <b>Cliente:</b> {contractInformations.client_name}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography align="left" variant="subtitle1">
            <b>Data de criação:</b> {contractInformations.created_at}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography align="left" variant="subtitle1">
            <b>Data de expiração:</b> {contractInformations.expires_at}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography align="left" variant="subtitle1">
            <b>Modelo de contrato: </b> {contractInformations.template}
          </Typography>
        </Grid>
        {variables ? (
          <>
            <Grid xs={12}>
              {' '}
              <Typography
                align="center"
                variant="h5"
                style={{ marginTop: '20px' }}
              >
                Dados do contrato
              </Typography>
            </Grid>
            <div className="horizontalDivider"></div>

            {Object.entries(variables).map((item) => {
              return (
                <Grid xs={12}>
                  <Typography align="left" variant="subtitle1">
                    <b>{item[0]}: </b> {item[1]}
                  </Typography>
                </Grid>
              );
            })}
          </>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
};

export default ContractInformations;
