import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { UserContext, UserProvider } from '../contexts/UserContext';
import Templates from '../pages/Templates';
import Contratos from '../pages/Contratos';
import ContractTypes from '../pages/ContractTypes';
import ContractualParts from '../pages/ContractualParts';
import Login from '../pages/Login';

const PrivateRoute = ({ children }) => {
  const userData = React.useContext(UserContext);
  return userData.user ? children : <Navigate to="/login" />;
};

const Navigation = () => (
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Templates />
            </PrivateRoute>
          }
        />
        <Route
          path="/contratos"
          element={
            <PrivateRoute>
              <Contratos />
            </PrivateRoute>
          }
        />
        <Route
          path="/tipos"
          element={
            <PrivateRoute>
              <ContractTypes />
            </PrivateRoute>
          }
        />
        <Route
          path="/partesContratuais"
          element={
            <PrivateRoute>
              <ContractualParts />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  </UserProvider>
);

export default Navigation;
