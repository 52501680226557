import React, { useEffect, useState } from 'react';
import ORBApi from '../../../Services/ORBApi';
import { Select, InputLabel, FormControl } from '@mui/material';

const TemplatesDropdown = (props) => {
  const [templates, setTemplates] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    ORBApi.loadTemplatesOptions().then((response) => {
      setTemplates(response.templates);
    });
  }, []);

  const onChangeHandler = (e) => {
    setSelected(e.target.value);
    props.onChange({
      id: e.target.value,
      name: e.target.selectedOptions[0].text,
    });
  };

  return (
    <FormControl style={{ width: '100%' }} variant="outlined" margin="normal">
      <InputLabel>Modelos</InputLabel>
      <Select
        native
        label="Modelo"
        onChange={onChangeHandler}
        value={selected}
        notched={selected > 0}
      >
        <option value="" />
        {templates.map((template) => {
          return (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          );
        })}
        ;
      </Select>
    </FormControl>
  );
};

export default TemplatesDropdown;
