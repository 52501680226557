import React, { useEffect, useState } from 'react';
import ORBNavBar from '../../components/ORBNavBar';
import ORBApi from '../../Services/ORBApi';
import ActionBar, { defaultFilters } from './components/ActionBar';
import Loading from '../../components/common/Loading';
import ContractTypesForm from './components/ContractTypesForm';
import ContractTypesTable from './components/ContractTypesTable';
import './style.css';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';

const ContractTypes = () => {
  const [data, setData] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [contractTypeOnPlay, setContractTypeOnPlay] = useState(null);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const showContractTypeForm = (contractType) => {
    if (contractType)
      setContractTypeOnPlay(JSON.parse(JSON.stringify(contractType)));
    // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
    else setContractTypeOnPlay(null);
    setFormVisible(true);
  };

  const onSaveContractType = () => {
    setFormVisible(false);
    loadData(filters.page);
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const loadData = (page) => {
    ORBApi.loadContractTypes(page, filters).then((data) => {
      setData(data);
    });
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  return (
    <div className="contract-type-page">
      <ORBNavBar title="Tipos de contratos" />
      <ActionBar
        formVisible={formVisible}
        setFormVisible={setFormVisible}
        onFilterChange={onFilterChangeHandler}
        onSaveContractType={onSaveContractType}
      />
      {!data ? (
        <Loading text="Buscando tipos de contratos ..." />
      ) : (
        <ContractTypesTable
          total_items={data.items_count}
          current_page={data.current_page}
          pages={data.total_pages}
          contract_types={data.types}
          onPageChange={onPageChangeHandler}
          showContractTypeForm={showContractTypeForm}
          loadData={loadData}
        />
      )}

      <div>
        <Modal
          size="lg"
          open={formVisible}
          onClose={() => {
            setFormVisible(!formVisible);
            setContractTypeOnPlay(null);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-container">
            <ContractTypesForm
              edit_contractType={contractTypeOnPlay}
              onSaveContractType={onSaveContractType}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ContractTypes;
