import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ORBApi from '../../../Services/ORBApi';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Loading from '../../../components/common/Loading';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import DeleteContractTypeModal from './DeleteContractTypeModal';
// import DeleteTemplateModal from './DeleteTemplateModal';

const ContractTypesTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [visible, setVisible] = useState(false);

  moment.locale('pt-br');
  return (
    <>
      <TableContainer component={Paper}>
        <Table className="page-table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'rgb(0, 34, 67)' }}>
              {['Tipo de contrato', 'Opções'].map((title, key) => (
                <TableCell
                  key={key}
                  align="center"
                  style={{ color: '#F5F5F5' }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.contract_types.length > 0
              ? props.contract_types.map((contractType) => {
                  return (
                    <TableRow key={contractType.id} className="row">
                      <TableCell
                        onClick={() => {
                          props.showContractTypeForm(contractType);
                        }}
                        className="cell clickable"
                        align="center"
                      >
                        {contractType.type}
                      </TableCell>{' '}
                      <TableCell className="cell " align="center">
                        <DeleteIcon
                          className="clickable"
                          onClick={() => {
                            setVisible(true);
                            setDeleteId(contractType.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        shape="rounded"
        className="table-pagination"
        count={props.pages}
        page={props.current_page}
        onChange={(e, value) => {
          props.onPageChange(value);
        }}
      />

      <div>
        <Modal
          open={visible}
          onClose={() => {
            setVisible(false);
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{ timeogut: 500 }}
        >
          <div className="modal-delete-contract-type">
            <DeleteContractTypeModal
              setVisible={setVisible}
              contractTypeId={deleteId}
              loadData={props.loadData}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ContractTypesTable;
