import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../components/common/Loading';
import { Grid, Button } from '@mui/material';
import ORBApi from '../../../Services/ORBApi';
import {
  ContractualPartsInputsPj,
  ContractualPartsInputsPf,
} from './ContractualPartsInputs';

const bodyPj = {
  company: '',
  cnpj: '',
  email:'',
  address: {
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    cep: '',
  },
  bank_data: {
    company: '',
    cnpj: '',
    bank: '',
    account: '',
    agency: '',
    pix: '',
  },
};
const bodyPf = {
  full_name: '',
  cpf: '',
  rg: '',
  email:'',
  address: {
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    cep: '',
  },
  bank_data: {
    full_name: '',
    cpf: '',
    bank: '',
    account: '',
    agency: '',
    pix: '',
  },
};
const ContractualPartsForm = (props) => {
  const [title, setTitle] = useState('');
  const [activeTab, setActiveTab] = useState('PJ');
  const [isSaving, setIsSaving] = useState(false);
  const [contractualPartsPj, setContractualPartsPj] = useState(bodyPj);
  const [contractualPartsPf, setContractualPartsPf] = useState(bodyPf);

  const saveContractualparts = () => {
    setIsSaving(true);
    if (props.edit_contractualParts) {
      if (props.edit_contractualParts.entity_type === 'PJ') {
        ORBApi.putContractualPartsPj(
          props.edit_contractualParts.id,
          contractualPartsPj,
        )
          .then(() => {
            toast.success('Edição finalizada');
            props.onSaveContractualParts();
          })
          .catch((error) => {
            putErrorHandler(error);
          })
          .finally(() => {
            setIsSaving(false);
          });
      } else {
        ORBApi.putContractualPartsPf(
          props.edit_contractualParts.id,
          contractualPartsPf,
        )
          .then(() => {
            toast.success('Edição finalizada PF');
            props.onSaveContractualParts();
          })
          .catch((error) => {
            putErrorHandler(error);
          })
          .finally(() => {
            setIsSaving(false);
          });
      }
    } else {
      if (activeTab === 'PJ') {
        ORBApi.postContractualPartsPj(contractualPartsPj)
          .then(() => {
            toast.success('Partes contratuais salvas');
            props.onSaveContractualParts();
          })
          .catch((error) => {
            postErrorHandler(error);
          })
          .finally(() => {
            setIsSaving(false);
            props.setIsVisible(false);
          });
      } else {
        ORBApi.postContractualPartsPf(contractualPartsPf)
          .then(() => {
            toast.success('Partes contratuais salvas PF');
            props.onSaveContractualParts();
          })
          .catch((error) => {
            postErrorHandler(error);
          })
          .finally(() => {
            setIsSaving(false);
            props.setIsVisible(false);
          });
      }
    }
  };
  const putErrorHandler = (error) => {
    switch (error.response.data.error) {
      case 'INVALID_DOCUMENT':
        toast.warn('Documento inválido');
        break;
      case 'INVALID_CONTRACTUAL_PART_ID':
        toast.warn('Parte contratual inválida');
        break;
      case 'CONTRACTUAL_PART_NOT_FOUND':
        toast.warn('Parte contratual não encontrada');
        break;
      default:
        toast.warn('Erro ao salvar partes contratuais');
        break;
    }
  };
  const postErrorHandler = (error) => {
    switch (error.response.data.error) {
      case 'INVALID_DOCUMENT':
        toast.warn('Documento inválido');
        break;
      default:
        toast.warn('Erro ao salvar partes contratuais');
        break;
    }
  };
  const handleChange = (field, value, category) => {
    if (activeTab === 'PJ') {
      if (category === '') {
        setContractualPartsPj((prevData) => ({
          ...prevData,
          [field]: value,
        }));
      } else {
        setContractualPartsPj((prevData) => ({
          ...prevData,
          [category]: {
            ...prevData[category],
            [field]: value,
          },
        }));
      }
    } else {
      if (category === '') {
        setContractualPartsPf((prevData) => ({
          ...prevData,
          [field]: value,
        }));
      } else {
        setContractualPartsPf((prevData) => ({
          ...prevData,
          [category]: {
            ...prevData[category],
            [field]: value,
          },
        }));
      }
    }
  };

  useEffect(() => {
    if (props.edit_contractualParts) {
      setTitle('Editar partes contratuais');

      if (props.edit_contractualParts.entity_type === 'PJ') {
        setContractualPartsPj(props.edit_contractualParts);
        setActiveTab('PJ');
      } else if (props.edit_contractualParts.entity_type === 'PF') {
        setContractualPartsPf(props.edit_contractualParts);
        setActiveTab('PF');
      }
    } else {
      setTitle('Criar partes contratuais');
    }
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr style={{ marginBottom: '10px' }} />
      <div className="tab-buttons">
        {activeTab === 'PF' && props.edit_contractualParts ? null : (
          <button
            className={`tab-button ${
              activeTab === 'PJ' ? 'tab-button-active' : ''
            }`}
            onClick={() => setActiveTab('PJ')}
          >
            {'formulário PJ'}
          </button>
        )}

        {activeTab === 'PJ' && props.edit_contractualParts ? null : (
          <button
            className={`tab-button ${
              activeTab === 'PF' ? 'tab-button-active' : ''
            }`}
            onClick={() => setActiveTab('PF')}
          >
            {'Formulário PF'}
          </button>
        )}
      </div>

      {activeTab === 'PJ' ? (
        <ContractualPartsInputsPj
          handleChange={handleChange}
          contractualPartsPj={contractualPartsPj}
        />
      ) : (
        <ContractualPartsInputsPf
          handleChange={handleChange}
          contractualPartsPf={contractualPartsPf}
        />
      )}
      <Grid>
        {!isSaving ? (
          <Button
            variant="contained"
            size="large"
            style={{ marginTop: '15px', backgroundColor: '#e33371' }}
            onClick={saveContractualparts}
          >
            {props.edit_contractualParts ? 'Editar' : 'Salvar'}
          </Button>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
};

export default ContractualPartsForm;
