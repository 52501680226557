import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../../components/common/Loading';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid, TextField, Button } from '@mui/material';
import ORBApi from '../../../Services/ORBApi';
import ContractTypesDropdown from '../../../components/ContractTypesDropdown';

const TemplatesForm = (props) => {
  const [template, setTemplate] = useState({ name: '', contract_type: '' });
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [fileName, setFileName] = useState('');
  const hiddenFileInput = React.useRef(null);

  const saveTemplate = () => {
    let formData = new FormData();
    formData.append('name', template.name);
    formData.append('contract_type', template.contract_type);
    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    setIsSaving(true);
    if (props.edit_templates) {
      if (
        formData.get('name') === '' ||
        formData.get('file' === null) ||
        formData.get('contract_type') === ''
      ) {
        setIsSaving(false);
        toast.warn('Por favor, preencha algum dos campos!');
        return;
      }
      ORBApi.patchTemplates(
        props.edit_templates.id,
        formData,
        'multipart/form-data',
      )
        .then(() => {
          toast.success('Edição Finalizada!');
          props.onSaveTemplate();
        })
        .catch((error) => {
          errorHandler();
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      if (formData.get('name') === '' || formData.get('file') === null) {
        toast.warn('Por favor, preencha todos os dados!');
        setIsSaving(false);
        return;
      }
      ORBApi.postTemplates(formData, 'multipart/form-data')
        .then(() => {
          toast.success('Template Salvo!');
          props.onSaveTemplate();
        })
        .catch((err) => {
          errorHandler(err);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const errorHandler = (error) => {
    switch (error.response.data.error) {
      case 'TEMPLATE_EXISTS':
        toast.warn('O template já existe');
        break;
      case 'TEMPLATE_HAS_INVALID_VARIABLE':
        toast.warn('O template possui uma variável inválida');
        break;
      case 'REQUIRED_VARIABLE_NOT_FOUND':
        toast.warn('Variável requerida nao encontrada');
        break;
      case 'CONTRACT_TYPE_NOT_FOUND':
        toast.warn('Tipo de contrato não encontrado');
        break;
      case 'INVALID_TEMPLATE_ID':
        toast.warn('Template inválido');
        break;
      case 'TEMPLATE_NOT_FOUND':
        toast.warn('Template inválido');
        break;
      default:
        toast.warn('Erro ao salvar template');
        break;
    }
  };

  useEffect(() => {
    if (props.edit_templates) {
      setTitle('Editar Modelo');

      setFileName(`${props.edit_templates.name}.docx`);
      setTemplate(props.edit_templates);
    } else {
      setTitle('Criar Modelo');
    }
  }, []);

  return (
    <>
      <h4>{title}</h4>
      <hr />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            value={template?.name}
            onChange={(e) =>
              setTemplate({
                ...template,
                name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <ContractTypesDropdown
            onChange={(type) => {
              setTemplate({
                ...template,
                contract_type: type,
              });
            }}
            value={template.contract_type}
            label="Tipos de contrato"
          />
        </Grid>
        <Grid item xs={6}>
          <input
            type="file"
            id="real-file"
            hidden
            ref={hiddenFileInput}
            onChange={(e) => {
              setSelectedFile(e.target.files[0]);
              setFileName(e.target.files[0].name);
            }}
          />
          <div
            style={{ display: 'flex', alignItems: 'flex-end' }}
            className="page-actionbar-item action-btn"
          >
            <CloudUploadIcon
              className="clickable"
              style={{ fontSize: '2rem', color: 'rgb(245, 0, 87)' }}
              color="secondary"
              onClick={() => {
                hiddenFileInput.current.click();
              }}
            />
            <span style={{ marginLeft: '10px', marginBottom: '3px' }}>
              {props.edit_templates
                ? fileName
                  ? fileName
                  : 'selecione um arquivo...'
                : selectedFile
                ? selectedFile.name
                : 'Selecione um arquivo...'}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid>
        {!isSaving ? (
          <Button
            style={{ marginTop: '15px', backgroundColor: 'rgb(245, 0, 87)' }}
            variant="contained"
            size="large"
            onClick={saveTemplate}
          >
            {' '}
            Salvar{' '}
          </Button>
        ) : (
          <Loading text="Salvando Template" />
        )}
      </Grid>
    </>
  );
};

export default TemplatesForm;
